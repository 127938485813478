import {list_functionality} from '@/services/list-functionality/list-functionality'
//Components
import VueTable from '@/common/VueTable/VueTable.vue'
import Actions from '@/common/VueTable/Actions.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import TableHeader from '@/common/TableHeader.vue'
import TaskDialog from './components/TasksDialog/TasksDialog.vue'
import makerequest from '@/services/makeRequestTo'
import request from "@/services/axios_instance";
export default {
    mixins: [list_functionality],
    components: {
        VueTable,
        DeleteDialog,
        TaskDialog,
        TableHeader,
        Actions
    },

    props: {
        template_id: [Number, String], //route param
        milestone_id: [Number, String] //route param
    },

    data: () => ({
        template_name: '',
        milestone_name: '',
        headers: [
            {text:'', width: 5 },
            {text: 'Title', value: 'title'},
            {text: 'Description', value: 'description'},
            {text: 'Group', value: 'role.name', width: 100},
            {text: 'Status', value: 'status', width: 100},
            {text: 'Days', value: 'days', width: 80},
            {text: 'Action', value: 'action', width: 150, align: 'center', sortable: false}
        ],
        table_config: {
            route_name: 'templates/milestone/task',
            add_message: 'New Task added successfully!',
            update_message: 'Task updated successfully!',
            delete_message: 'Task deleted successfully!'
        }
    }),

    computed: {
        dynamic_api() {
            return `api/milestone/${this.milestone_id}/task`
        },
        dynamic_bulk_delete_api() {
            return `api/milestone/${this.milestone_id}/task/bulk-delete`
        },
        paths() {
            return [
                {text: 'Dashboard', disabled: false, route: {name: 'default-content'}},
                {text: 'Templates', disabled: false, route: {name: 'templates'}},
                {
                    text: 'Milestones',
                    disabled: false,
                    route: {path: `/dashboard/templates/${this.template_id}/milestone`}
                },
                {text: 'Tasks', disabled: true, route: null}
            ]
        },
        table_title() {
            if (this.milestone_name && this.template_name) {
                return this.template_name + ': ' + this.milestone_name
            }
            return ''
        }
    },
    mounted() {
        this.$event.$emit('path-change', this.paths)
    },
    created() {
        this.fill_table_via_url(this.dynamic_api, true)
        makerequest
            .get_milestones(`api/template/${this.template_id}`)
            .then(({data}) => {
                this.template_name = data.name
            })
            .finally(() => (this.loading = false))

        makerequest
            .get_milestones(
                `api/template/${this.template_id}/milestone/${this.milestone_id}`
            )
            .then(({data}) => {
                this.milestone_name = data.title
            })
            .finally(() => (this.loading = false))
    },

    methods: {
        short_description_text(text) {
            if (!text || typeof text === 'undefined') return ''
            return text.length > 20 ? text.slice(0, 19) + '...' : text
        },
        load_more() {
            this.load_more_via_url(this.dynamic_api)
        },
        handleSortMilestoneTasks(items) {
            let new_order = items.map(i => {return i.id})
            request.post('api/task/re-order', { milestone: parseInt(this.milestone_id), order: new_order})
                .then(({data}) => {
                    this.appSnackbar( 'Task re-ordered!')
                })
        }
    }
}